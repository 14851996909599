import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
export interface ItemState {
	appId: number;
	name: string;
}
export interface ListingState {
	selected: number;
	list: ItemState[];
}

const initialState: ListingState = {
	selected: -1,
	list: [],
};

export const appListSlice = createSlice({
	name: "appList",
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<ItemState[]>) => {
			state.list = action.payload
				.filter((item) => item.appId > 2)
				.sort((a, b) => a.appId - b.appId);
		},
		setSelected: (state, action: PayloadAction<number>) => {
			state.selected = state.list[0]?.appId;
			const appIds = state.list.map(({ appId }) => appId);
			if (appIds.includes(action.payload)) state.selected = action.payload;
		},
	},
});

export const { setList, setSelected } = appListSlice.actions;
export const selectAppList = (state: RootState) => state.appLister;

export const selectCurrentApp = createSelector(selectAppList, (appList) =>
	appList.list.find((app) => app.appId === appList.selected)
);

export default appListSlice.reducer;
