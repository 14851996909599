import { store } from "../reducer/store";
import { logout as logoutProfile } from "../reducer/profileSlice";
import { alerting, setRedirectTo } from "../reducer/alerterSlice";
import { returnCodeDict } from "./constant";

interface errorFace {
	response?: {
		data?: any;
		status?: number;
		message?: string;
	};
}
interface ResponseFace {
	data: {
		returnCode: number;
		message: string;
	};
}
const kickCodeStr = [
	"login token expired",
	"invalid login token",
	"login token type invalid",
].join(",");
export function appError(response: ResponseFace) {
	console.warn(response.data);
	const returnCode = response.data.returnCode as number;
	const message = response.data.message as string;
	const dbMessage = message.toLowerCase();
	const dictMessage = (returnCodeDict[returnCode] || "").toLowerCase();
	const messageMatched = dictMessage && kickCodeStr.includes(dictMessage);
	if (kickCodeStr.includes(dbMessage) || messageMatched) {
		store.dispatch(setRedirectTo("/login"));
		store.dispatch(logoutProfile());
	}
	store.dispatch(alerting("warning", message));
	return;
}
export default function (error: errorFace) {
	const response = error.response;
	if (!response) return Promise.reject(error);
	if (!response.status) return Promise.reject(error);
	const statusCode = response.status;
	console.error(response);
	store.dispatch(alerting("error", "Error " + response.data));
	if (statusCode === 401) {
		// logout user
	}

	if (statusCode >= 500) {
		// show server error
	}

	if (statusCode === 400) {
		// show bad request error
	}

	return Promise.reject(error);
}
