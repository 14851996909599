import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18nSetting";
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from "./reducer/store";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme({
	palette: {
		primary: {
			main: "#0d3d6e",
		},
		secondary: {
			main: "#00baff",
		},
		warning: {
			main: "#ff0000",
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<App />
					</PersistGate>
				</Provider>
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
