import styles from "./index.module.scss";
import Box from "@mui/material/Box";
interface ComponentProps {
	children: JSX.Element[] | JSX.Element;
	className?: string;
}
export default function (props: ComponentProps) {
	const className = styles.loginBox + " " + props.className;
	return <Box className={className}>{props.children}</Box>;
}
