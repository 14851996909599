import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface ConfirmDialogState {
	open?: boolean;
	id: number;
	name: string;
}

const initialState: ConfirmDialogState = {
	open: false,
	id: 0,
	name: "",
};

export const confirmDialogSlice = createSlice({
	name: "confirmDialog",
	initialState,
	reducers: {
		openConfirmDialog: (state, action: PayloadAction<ConfirmDialogState>) => {
			state.open = true;
			state.id = action.payload.id;
			state.name = action.payload.name;
		},
		closeConfirmDialog: (state) => {
			state.open = false;
		},
	},
});

export const { openConfirmDialog, closeConfirmDialog } =
	confirmDialogSlice.actions;

export const selectConfirmDialog = (state: RootState) => state.confirmDialog;

export default confirmDialogSlice.reducer;
