import getAxiosInstance from "./axios";
const host = process.env.REACT_APP_AUTH_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "backend");
interface VerifyAccessTokenFace {
	accessToken: string;
	appId: string;
	timestamp: string;
	sign: string;
	nonce: string;
}
const apiList = {
	verifyAccessToken: (params: VerifyAccessTokenFace) =>
		api({ url: "verifyAccessToken", params }),
	nopath: (params: any) => api({ url: "nopath", params }),
	featureList: (appId: number | string) =>
		api({ url: "featureList", params: { appId } }),
};
export default apiList;
