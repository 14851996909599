//only import once in App.tsx
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../reducer/hooks";
import { hasPath } from "../utils/helper";
import { selectProfile, login as profileLogin } from "../reducer/profileSlice";
import { alerting } from "../reducer/alerterSlice";
import { setList, setSelected } from "../reducer/appListSlice";
import api from "../api";

//purpose
//1. determine the state of isLogged and isSuperAdmin
//2. save app list
export default function Auth() {
	const dispatch = useAppDispatch();
	const { lastAppId, token } = useAppSelector(selectProfile);
	useEffect(() => {
		if (!token) return;
		async function verifyToken() {
			const result: any = await api.admin.appIdList().catch(Error);
			if (!result) return;
			const isMissing = !hasPath(result, ["data.0.AppId", "data.0.Name"]);
			if (isMissing) return dispatch(alerting("error", "data is missing"));
			interface appItemFace {
				AppId: number;
				AppUuid: string;
				Name: string;
			}
			const data: appItemFace[] = result.data;
			const isSuperAdmin = data.some((item) => item.AppId === 1);
			const list = data.map(({ AppId, Name, AppUuid }) => ({
				appId: AppId,
				name: Name,
				appUuid: AppUuid,
			}));
			dispatch(profileLogin(isSuperAdmin));
			dispatch(setList(list));
			dispatch(setSelected(lastAppId));
		}
		verifyToken();
	}, [token]);
	return <></>;
}
