import { useState, useEffect } from "react";
import style from "../../styles/contentWrap.module.scss";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { generateNonce } from "../../utils/helper";
import { alerting } from "../../reducer/alerterSlice";
import api from "../../api";
import LiItem from "../../components/LiItem";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { fetchAppList, selectLister } from "../../reducer/superAppListSlice";

const initUserInput = {
	appName: "",
	appType: "0",
	masterApp: "0",
	loginCallbackUrl: "",
	isEnableTwoFA: "0",
	iconUrl: "",
	loginTokenDuration: 60,
};

const initCreatedApp = {
	isSuccess: false,
	name: "",
	secret: "",
};

export default function CreateApp() {
	const dispatch = useAppDispatch();
	const [userInput, setUserInput] = useState(initUserInput);

	const [createdApp, setCreatedApp] = useState(initCreatedApp);
	const copySecret = async () => {
		try {
			if ("clipboard" in navigator) {
				await navigator.clipboard.writeText(createdApp.secret);
			} else {
				document.execCommand("copy", true, createdApp.secret);
			}
			dispatch(alerting("success", "Secret copied!"));
		} catch (e) {
			alerting("error", "Cannot copy secret!");
		}
	};
	useEffect(() => {
		dispatch(fetchAppList()); //used thunk
	}, [dispatch]);

	const superAppLister = useAppSelector(selectLister);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSelectChange = (e: SelectChangeEvent<string>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
		if (userInput.appType === "0") {
			setUserInput((prev) => ({ ...prev, masterApp: "0" }));
		}
	};
	const submit = async () => {
		if (userInput.appName === "")
			return dispatch(alerting("warning", "app name is empty"));
		if (userInput.loginCallbackUrl === "")
			return dispatch(alerting("warning", "login URL is empty"));
		if (userInput.appType === "1" && userInput.masterApp === "0")
			return dispatch(alerting("warning", "master app is empty"));
		const params = {
			...userInput,
			isEnableTwoFA: userInput.isEnableTwoFA === "1",
			loginTokenDuration: Number(userInput.loginTokenDuration) * 60,
			derivedFromAppId: Number(userInput.masterApp),
			derivedFromAppUuid: superAppLister.list.find(
				(item) => item.appId === Number(userInput.masterApp)
			)?.appUuid,
		};
		const nonce = generateNonce();
		const result: any = await api.superAdmin
			.createApp({ nonce, ...params })
			.catch(Error);
		if (!result) return;
		const secret = result.data.secret;
		setCreatedApp({ isSuccess: true, secret, name: userInput.appName });
		// dispatch(alerting("success", `${params.appName} is created`));
		setUserInput({ ...initUserInput });
	};
	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>Create App</div>
			{createdApp.isSuccess ? (
				<>
					<div>{createdApp.name} is created</div>
					<div className={style["flexContainer"]}>
						<div>Secret: </div>
						<div
							className={`${style["flexContainer"]} ${style["copySecretContainer"]}`}
							onClick={copySecret}
						>
							<span className={style["forCopyText"]}>{createdApp.secret}</span>
							<FileCopyIcon fontSize="inherit" />
						</div>
					</div>
					<Button
						color="primary"
						variant="contained"
						className={style["confirmBtn"]}
						onClick={() => setCreatedApp(initCreatedApp)}
					>
						Confirm
					</Button>
				</>
			) : (
				<>
					<LiItem title="App Name" mustFill>
						<TextField
							variant="outlined"
							name="appName"
							value={userInput.appName}
							onChange={onInputChange}
						/>
					</LiItem>
					<LiItem title="App Type" mustFill>
						<Select
							variant="outlined"
							name="appType"
							value={userInput.appType}
							onChange={onSelectChange}
							displayEmpty
						>
							<MenuItem value="0">Master</MenuItem>
							<MenuItem value="1">Derived</MenuItem>
						</Select>
					</LiItem>
					<LiItem title="Master App">
						<Select
							variant="outlined"
							name="masterApp"
							value={userInput.appType === "0" ? "0" : userInput.masterApp}
							onChange={onSelectChange}
							displayEmpty
							disabled={userInput.appType === "0"}
						>
							<MenuItem disabled value="0">
								<em>Choose a Master App</em>
							</MenuItem>
							{superAppLister.list.map(
								(item) =>
									item.derivedFrom === 0 && (
										<MenuItem value={item.appId} key={item.appUuid}>
											{item.appName}
										</MenuItem>
									)
							)}
							{/* <MenuItem value="1">Master</MenuItem>
							<MenuItem value="0">Derived</MenuItem> */}
						</Select>
					</LiItem>
					<LiItem title="Login URL" mustFill>
						<TextField
							variant="outlined"
							name="loginCallbackUrl"
							value={userInput.loginCallbackUrl}
							onChange={onInputChange}
						/>
					</LiItem>
					<LiItem title="Enable Two Fa">
						<Select
							name="isEnableTwoFA"
							value={userInput.isEnableTwoFA}
							onChange={onSelectChange}
							displayEmpty
						>
							<MenuItem value="1">true</MenuItem>
							<MenuItem value="0">false</MenuItem>
						</Select>
					</LiItem>
					<LiItem title="Icon URL">
						<TextField
							variant="outlined"
							name="iconUrl"
							value={userInput.iconUrl}
							onChange={onInputChange}
						/>
					</LiItem>
					<LiItem title="Token Duration (Minute)">
						<TextField
							variant="outlined"
							name="loginTokenDuration"
							type="number"
							onChange={onInputChange}
							value={userInput.loginTokenDuration}
						/>
					</LiItem>
					<div className={style["li-button"]}>
						<Button color="primary" variant="contained" onClick={submit}>
							Create
						</Button>
					</div>
				</>
			)}
		</div>
	);
}
