import { IFeature, IParentFeatureList } from "../interface/appAdminFace";
import { store } from "../reducer/store";

const state = store.getState().appAdmin;

export const parentFeatureList: IParentFeatureList = {
    id: -1,
    name: "All Feature",
    children: state.featureList,
    featureType:1
};

function getNodeById(nodes: IFeature | IParentFeatureList, id: number) {
    if (nodes.id === id) {
        return nodes;
    } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node: IFeature | IParentFeatureList) => {
            if (!!getNodeById(node, id)) {
                result = getNodeById(node, id);
            }
        });
        return result;
    }
    return null;
}

export function getChildById(node: IFeature | IParentFeatureList, id: number) {
    let array: number[] = [];

    function getAllChild(nodes: IFeature | IParentFeatureList | null) {
        if (nodes === null) return [];
        array.push(nodes.id);
        if (Array.isArray(nodes.children)) {
            nodes.children.forEach((node: IFeature | IParentFeatureList) => {
                array = [...array, ...getAllChild(node)];
                array = array.filter((v, i) => array.indexOf(v) === i);
            });
        }
        return array;
    }

    return getAllChild(getNodeById(node, id));
}

