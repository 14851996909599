import style from "./AppManagement.module.scss";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { selectAppList } from "../../reducer/appListSlice";
import { alerting } from "../../reducer/alerterSlice";
import api from "../../api";
import { hasPath } from "../../utils/helper";
import LiItem from "../../components/LiItem";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ErrorIcon from "@mui/icons-material/Error";
import { margin } from "@mui/system";

const initUserInput = {
	appName: "",
	appType: "",
	masterApp: "",
	isEnableTwoFA: "FALSE" as const,
	isEnableWhitelist: "FALSE" as const,
	iconUrl: "",
	loginTokenDuration: 0,
	// secret: "",
	loginCallbackUrl: "",
	appId: -1,
	appUuid: "",
};
enum RegenerateSecretStatus {
	standBy,
	pending,
	success,
}

const initRegenerateSecret = {
	status: RegenerateSecretStatus.standBy,
	secret: "",
};
interface IuserInput {
	appName: string;
	appType: string;
	masterApp: string;
	isEnableTwoFA: "FALSE" | "TRUE";
	isEnableWhitelist: "FALSE" | "TRUE";
	iconUrl: string;
	loginTokenDuration: number;
	// secret: string;
	loginCallbackUrl: string;
	appId: number;
	appUuid: string;
}

export default function AppManagement() {
	const { t } = useTranslation();
	const [isInEdit, setIsInEdit] = React.useState(false);
	const [regeneratedSecret, setRegeneratedSecret] =
		React.useState(initRegenerateSecret);
	const [userInput, setUserInput] = React.useState<IuserInput>(initUserInput);
	const dispatch = useAppDispatch();
	const appId = useAppSelector(selectAppList).selected;

	const originalDataRef = useRef<IuserInput>(initUserInput);

	useEffect(() => {
		async function fetchAppConfig() {
			if (appId <= 0) return;
			const result: any = await api.admin.getAppConfig(appId);
			if (!result) return;
			const data = result.data;
			const isMissing = !hasPath(data, [
				"appName",
				"isEnableTwoFA",
				"loginCallbackUrl",
				"loginTokenDuration",
			]);
			if (isMissing) return dispatch(alerting("error", "data is missing"));
			const newData = {
				...data,
				appType: data.masterApp ? "Derived App" : "Master App",
				masterApp: data.masterApp?.appName,
				loginTokenDuration: Math.floor(data.loginTokenDuration / 60),
				isEnableTwoFA: data.isEnableTwoFA ? "TRUE" : "FALSE",
				isEnableWhitelist: data.isEnableWhitelist ? "TRUE" : "FALSE",
			};

			originalDataRef.current = newData;

			setUserInput(newData);
		}
		fetchAppConfig();
	}, [dispatch, appId]);
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		function getValue() {
			const value = e.target.value;
			return key === "loginTokenDuration" ? Number(value) : value;
		}
		const value = getValue();
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSelectChange =
		(key: "isEnableTwoFA" | "isEnableWhitelist") =>
		(e: SelectChangeEvent<string>) => {
			setUserInput((input) => ({ ...input, [key]: e.target.value }));
		};
	//swagger schema, Update flag list
	//1-2FA option; 2-name; 4-login callback URL; 8-icon URL; 16-secret; 32-login token duration; 64-app whitelist
	// e.g 2+8= 10, updateField:10 only name and icon URL will be updated
	// 127= include everything
	const submit = async () => {
		const params = {
			updateField: 127,
			...userInput,
			isEnableTwoFA: userInput.isEnableTwoFA === "TRUE",
			isEnableWhitelist: userInput.isEnableWhitelist === "TRUE",
			loginTokenDuration: userInput.loginTokenDuration * 60,
		};
		const result = await api.admin.updateAppConfig(params);
		if (!result) return;
		dispatch(alerting("success", "Saved Config"));
		setIsInEdit(false);
		originalDataRef.current = userInput;
	};
	const regenerateSecret = async () => {
		const result = await api.admin.regenerateSecret({ appId });
		if (!result) return;
		// dispatch(alerting("success", "Saved Config"));
		setRegeneratedSecret({
			status: RegenerateSecretStatus.success,
			secret: result.data,
		});
	};

	const copySecret = async () => {
		try {
			if ("clipboard" in navigator) {
				await navigator.clipboard.writeText(regeneratedSecret.secret);
			} else {
				document.execCommand("copy", true, regeneratedSecret.secret);
			}
			dispatch(alerting("success", "Secret copied!"));
		} catch (e) {
			alerting("error", "Cannot copy secret!");
		}
	};

	if (userInput.appId <= 0) return <></>;

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("appAdmin.appManagement")}</div>
			{regeneratedSecret.status === RegenerateSecretStatus.standBy && (
				<>
					<LiItem title="App ID" className={style["li-container"]}>
						<TextField
							disabled={true}
							variant="outlined"
							name="appId"
							onChange={onInputChange}
							value={userInput.appId}
						/>
					</LiItem>
					<LiItem title="App UUID" className={style["li-container"]}>
						<TextField
							disabled={true}
							variant="outlined"
							name="appUUID"
							onChange={onInputChange}
							value={userInput.appUuid}
						/>
					</LiItem>
					<LiItem title="App Type" className={style["li-container"]}>
						<TextField
							disabled
							variant="outlined"
							name="appType"
							onChange={onInputChange}
							value={userInput.appType}
						/>
					</LiItem>
					{userInput.masterApp && (
						<LiItem title="Master App" className={style["li-container"]}>
							<TextField
								disabled
								variant="outlined"
								name="masterApp"
								onChange={onInputChange}
								value={userInput.masterApp}
							/>
						</LiItem>
					)}
					<LiItem title="App Name" className={style["li-container"]}>
						<TextField
							disabled={!isInEdit}
							variant="outlined"
							name="appName"
							onChange={onInputChange}
							value={userInput.appName}
						/>
					</LiItem>
					<LiItem
						title={t("appAdmin.googleAuthentication")}
						className={style["li-container"]}
					>
						<Select
							className={style["selectInputWithoutPadding"]}
							disabled={!isInEdit}
							name="isEnableTwoFA"
							value={userInput.isEnableTwoFA}
							onChange={onSelectChange("isEnableTwoFA")}
							displayEmpty
							sx={{
								color: "var(--black)",
								bgcolor: "var(--white)",
							}}
						>
							<MenuItem value={"TRUE"}>Enable</MenuItem>
							<MenuItem value={"FALSE"}>Disable</MenuItem>
						</Select>
					</LiItem>
					<LiItem
						title={t("appAdmin.whitelist")}
						className={style["li-container"]}
					>
						<Select
							className={style["selectInputWithoutPadding"]}
							disabled={!isInEdit}
							name="isEnableWhitelist"
							value={userInput.isEnableWhitelist}
							onChange={onSelectChange("isEnableWhitelist")}
							displayEmpty
							sx={{
								color: "var(--black)",
								bgcolor: "var(--white)",
							}}
						>
							<MenuItem value={"TRUE"}>Enable</MenuItem>
							<MenuItem value={"FALSE"}>Disable</MenuItem>
						</Select>
					</LiItem>
					<LiItem title="Icon URL" className={style["li-container"]}>
						<TextField
							disabled={!isInEdit}
							variant="outlined"
							name="iconUrl"
							onChange={onInputChange}
							value={userInput.iconUrl}
						/>
					</LiItem>
					<LiItem title="Login URL" className={style["li-container"]}>
						<TextField
							disabled={!isInEdit}
							variant="outlined"
							name="loginCallbackUrl"
							onChange={onInputChange}
							value={userInput.loginCallbackUrl}
						/>
					</LiItem>
					<LiItem
						title="Token Duration (minute)"
						className={style["li-container"]}
					>
						<TextField
							disabled={!isInEdit}
							variant="outlined"
							name="loginTokenDuration"
							onChange={onInputChange}
							type="number"
							value={userInput.loginTokenDuration}
						/>
					</LiItem>
					<LiItem
						title="Secret"
						className={`${style["regenSecretContainer"]} ${style["li-container"]}`}
					>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								setRegeneratedSecret((prev) => ({
									...prev,
									status: RegenerateSecretStatus.pending,
								}));
							}}
							className={style["regenSecretBtn"]}
						>
							Regenerate
						</Button>
						<div className={style["regenSecretWarning"]}>
							<ErrorIcon fontSize="inherit" />
							<span>{t("appAdmin.regenerateSecretWarning")}</span>
						</div>
					</LiItem>
					{!isInEdit && (
						<div className={`${style["li-button"]} ${style["editAppBtn"]}`}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => setIsInEdit(true)}
							>
								Edit
							</Button>
						</div>
					)}
					{isInEdit && (
						<div className={style.btnGroups}>
							<Button color="primary" variant="contained" onClick={submit}>
								Save
							</Button>
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									setIsInEdit(false);
									setUserInput(originalDataRef.current);
								}}
							>
								Cancel
							</Button>
						</div>
					)}
				</>
			)}

			{regeneratedSecret.status === RegenerateSecretStatus.pending && (
				<>
					<div>
						Status: <span style={{ color: "#FF0000" }}>Unregenerate</span>
					</div>
					<div className={style["flexContainer"]}>
						<div>
							{userInput.appName} Secret:{" "}
							<span className={style["pendingRegenerateSecretText"]}>
								********
							</span>
						</div>
					</div>
					<Button
						color="primary"
						variant="contained"
						style={{ marginRight: 12 }}
						className={style["confirmBtn"]}
						onClick={regenerateSecret}
					>
						Confirm Regenerate
					</Button>
					<Button
						color="inherit"
						variant="contained"
						className={style["confirmBtn"]}
						onClick={() => setRegeneratedSecret(initRegenerateSecret)}
					>
						Back
					</Button>
				</>
			)}

			{regeneratedSecret.status === RegenerateSecretStatus.success && (
				<>
					<div>
						Status: <span style={{ color: "#29abc1" }}>Regenerated</span>
					</div>
					<div className={style["flexContainer"]}>
						<div>{userInput.appName} Secret: </div>
						<div
							className={`${style["flexContainer"]} ${style["successRegeneratedSecretText"]}`}
							onClick={copySecret}
						>
							<span className={`${style["forCopyText"]} `}>
								{regeneratedSecret.secret}
							</span>
							<FileCopyIcon
								fontSize="inherit"
								className={`${style["copyIcon"]} `}
							/>
						</div>
					</div>
					<Button
						color="primary"
						variant="contained"
						className={style["confirmBtn"]}
						onClick={() => setRegeneratedSecret(initRegenerateSecret)}
					>
						Back
					</Button>
				</>
			)}
		</div>
	);
}
