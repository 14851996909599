import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import style from "../../styles/contentWrap.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MultiSelection from "../../components/MultiSelection";
import { useState, useEffect } from "react";
import { alerting } from "../../reducer/alerterSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { selectAppList } from "../../reducer/appListSlice";
import { addAppUser, updateAppUser } from "../../reducer/appUserSlice";
import { fetchRoleList, selectRoleList } from "../../reducer/appAdminSlice";
import { hasPath, renameObjKey } from "../../utils/helper";

const LiItem = (props: any) => {
	const { children, title } = props;
	return (
		<div className={style["li-container"]}>
			<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
				{title}
			</div>
			<div className={style["li-value"]}>{children}</div>
		</div>
	);
};
const initUserInput = {
	email: "",
	alias: "",
	status: 2, // disabled
};
export default function UserInfoGuard() {
	let navigate = useNavigate();
	let location: any = useLocation();
	const isLost = !hasPath(location, "state.type");
	useEffect(() => {
		if (isLost) return navigate("/app-admin/user-management");
	}, [isLost]);

	if (isLost) return <></>;
	return <UserInfo />;
}
function UserInfo() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	let location: any = useLocation();
	const type = location.state.type;
	const isEdit = type === "edit";
	const dispatch = useAppDispatch();
	const [userInput, setUserInput] = useState(initUserInput);
	const [theRoles, setTheRoles] = useState([]);
	const appId = useAppSelector(selectAppList).selected;
	useEffect(() => {
		if (isEdit) {
			const { userInfo } = location.state;
			const params = {
				alias: userInfo.alias,
				status: userInfo.status,
			};
			setUserInput((inputs) => ({ ...inputs, ...params }));
		}
	}, []);
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSelectChange = (e: SelectChangeEvent<string>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSubmit = async () => {
		const base = {
			appId,
			alias: userInput.alias,
			roleIdList: theRoles,
		};
		if (isEdit) {
			onSubmitEdit(base);
		} else {
			onSubmitAdd(base);
		}
	};
	const onSubmitAdd = async (base: any) => {
		if (theRoles.length === 0)
			return dispatch(alerting("error", "Must assign at least one role"));
		try {
			const params = { ...base, email: userInput.email.trim() };
			await dispatch(addAppUser(params)).unwrap();
			dispatch(alerting("success", `added user ${userInput.alias}`));
			navigate(-1);
		} catch {}
	};
	const onSubmitEdit = async (base: any) => {
		const userId = location.state.userInfo.id;
		if (!userId) return dispatch(alerting("error", "no userId"));
		const updateField = 14; // enable to update role, status, alias
		const params = { ...base, status: userInput.status, userId, updateField };
		try {
			await dispatch(updateAppUser(params)).unwrap();
			dispatch(alerting("success", `edited user ${userInput.alias}`));
			navigate(-1);
		} catch {}
	};
	return (
		<div className={style["content-wrap"]}>
			<div className={`${style.title} capitalize`}>
				{type} {t("appAdmin.user")}
			</div>
			{!isEdit && (
				<LiItem title={t("appAdmin.email")}>
					<TextField
						name="email"
						value={userInput.email}
						onChange={onInputChange}
						variant="outlined"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</LiItem>
			)}
			<LiItem title={t("appAdmin.alias")}>
				<TextField
					name="alias"
					value={userInput.alias}
					onChange={onInputChange}
					variant="outlined"
					inputProps={{
						style: {
							padding: 5,
						},
					}}
				/>
			</LiItem>
			{isEdit && (
				<LiItem title={t("appAdmin.status")}>
					<Select
						className={style["selectInputWithoutPadding"]}
						value={userInput.status.toString()}
						defaultValue=""
						onChange={onSelectChange}
						name="status"
					>
						<MenuItem value={1}>{t("enabled")}</MenuItem>
						<MenuItem value={2}>{t("disabled")}</MenuItem>
					</Select>
				</LiItem>
			)}
			<LiItem title={`${t("appAdmin.assign")} ${t("appAdmin.role")}`}>
				<RoleSelection theRoles={theRoles} setTheRoles={setTheRoles} />
			</LiItem>
			<div className={`${style["li-button"]} ${style["li-button-long"]}`}>
				<Button color="primary" variant="contained" onClick={onSubmit}>
					{t("account.submit")}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/app-admin/user-management")}
				>
					{t("account.back")}
				</Button>
			</div>
		</div>
	);
}
function RoleSelection(props: any) {
	let location: any = useLocation();
	const dispatch = useAppDispatch();
	const { theRoles, setTheRoles } = props;
	const appId = useAppSelector(selectAppList).selected;
	const rawRoleList = useAppSelector(selectRoleList) || [];
	const roleList = rawRoleList.map((role) => {
		const first = renameObjKey(role, "roleName", "name");
		return renameObjKey(first, "roleId", "id");
	});

	useEffect(() => {
		const initRoles =
			location.state?.userInfo?.roleList.map((role: any) => role.roleId) || [];
		setTheRoles(initRoles);
	}, []);
	useEffect(() => {
		dispatch(fetchRoleList(appId));
	}, [dispatch, appId]);
	return (
		<MultiSelection
			list={roleList}
			selectedItems={theRoles}
			setSelected={setTheRoles}
		/>
	);
}
