import Button from "@mui/material/Button";
interface ComponentProps {
	children: JSX.Element[] | JSX.Element;
}
export default function (props: any) {
	const { children, onClick } = props;
	return (
		<Button
			sx={{
				backgroundColor: "var(--main)",
				mt: 3,
			}}
			variant="contained"
			onClick={onClick}
		>
			{props.children}
		</Button>
	);
}
