import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { drawerRoutesConfig, versionNumber } from "../../utils/config";
import bgImage from "../../assets/login-background.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import api from "../../api";
import { useAppSelector, useAppDispatch } from "../../reducer/hooks";
import { selectAppList, setSelected } from "../../reducer/appListSlice";
import {
	logout as logoutProfile,
	selectProfile,
	setLastAppId,
} from "../../reducer/profileSlice";

const drawerWidth = 240;

interface IRouteInfo {
	key: string;
	route: string;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),

	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft({ children }: { children: any }) {
	const profile = useAppSelector(selectProfile);
	const appLister = useAppSelector(selectAppList);
	const isSuperAdmin = profile.isSuperAdmin;
	const routeInfo = isSuperAdmin
		? drawerRoutesConfig.superAdmin
		: drawerRoutesConfig.admin;
	const { t } = useTranslation();
	let navigate = useNavigate();
	const theme = useTheme();
	const location = useLocation();
	// const [open, setOpen] = React.useState(true);
	const open = true;
	const dispatch = useAppDispatch();
	const setSelectedApp = (appId: number) => dispatch(setSelected(appId));
	const setLastApp = (appId: number) => dispatch(setLastAppId(appId));
	const activeTabIndex = routeInfo.findIndex((element) =>
		location.pathname.includes(element.route)
	);

	// const handleDrawerOpen = () => {
	// 	setOpen(true);
	// };

	// const handleDrawerClose = () => {
	// 	setOpen(false);
	// };

	const handleAppChange = (event: SelectChangeEvent) => {
		const appId = Number(event.target.value);
		setLastApp(appId);
		setSelectedApp(appId);
		navigate("/app-admin/app-management");
	};

	async function logout() {
		const result: any = await api.account.logout().catch(Error);
		dispatch(logoutProfile());
		navigate("/login");
	}

	function tabOnClick(item: IRouteInfo, index: number) {
		navigate(item.route);
	}

	return (
		<Box
			sx={{
				display: "flex",
				height: "100vh",
				backgroundImage: `url(${bgImage})`,
				backgroundSize: "cover",
			}}
		>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar
					sx={{
						bgcolor: "var(--black)",
						color: "var(--white)",
						justifyContent: "space-between",
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							// onClick={handleDrawerOpen}
							edge="start"
							sx={{ mr: 2, ...(open && { display: "none" }) }}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap component="div">
							{t("account.userAuth")}
						</Typography>
					</Box>

					<Box
						sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
						onClick={logout}
					>
						<LogoutIcon color="secondary" />
						<Typography
							variant="h6"
							align="right"
							noWrap
							component="div"
							color="var(--log-inout)"
						>
							{t("account.logout")}
						</Typography>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				PaperProps={{
					sx: {
						backgroundColor: "var(--main)",
						color: "var(--white)",
					},
				}}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader>
					{/* <IconButton onClick={handleDrawerClose}>
						{theme.direction === "ltr" ? (
							<ChevronLeftIcon />
						) : (
							<ChevronRightIcon />
						)}
					</IconButton> */}
				</DrawerHeader>
				{!isSuperAdmin && (
					<Select
						value={appLister.selected.toString()}
						onChange={handleAppChange}
						displayEmpty
						sx={{
							color: "var(--black)",
							bgcolor: "var(--white)",
							mx: 2,
						}}
					>
						{appLister.list.map((app) => (
							<MenuItem key={app.appId} value={app.appId}>
								{app.name}
							</MenuItem>
						))}
					</Select>
				)}
				<Divider />
				<List>
					{routeInfo.map((item, index) => (
						<ListItem
							key={item.key}
							onClick={() => tabOnClick(item, index)}
							sx={{
								bgcolor: `${
									activeTabIndex === index ? "var(--log-inout)" : ""
								}`,
								color: `${activeTabIndex === index ? "var(--white)" : ""}`,
								cursor: "pointer",
							}}
						>
							<ListItemText sx={{ mx: 1 }} primary={`${t(item.key)}`} />
						</ListItem>
					))}
				</List>
				<div
					style={{
						fontSize: "13px",
						width: "240px",
						position: "fixed",
						bottom: "12px",
						textAlign: "center",
					}}
				>
					Version: v{versionNumber}
				</div>
			</Drawer>
			<Main open={open}>
				<DrawerHeader />
				{children}
			</Main>
		</Box>
	);
}
