import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface IActiveState {
	id?: number;
}

const initialState: IActiveState = {
	id: 1,
};

export const userSetting = createSlice({
	name: "userSetting",
	initialState,
	reducers: {
		setActiveTab: (state, action: PayloadAction<number>) => {
			state.id = action.payload;
		},
	},
});

export const { setActiveTab } = userSetting.actions;
export const selectUserSetting = (state: RootState) => state.userSetting;
export default userSetting.reducer;
