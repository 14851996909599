// import style from "../styles/contentWrap.module.scss";
import style from "./LiItem.module.scss";
export default function LiItem(props: {
	title: string;
	children: JSX.Element[] | JSX.Element;
	className?: string;
	mustFill?: boolean;
}) {
	const { children, title, mustFill } = props;
	const propClass = props.className || "";
	const mustFillClass = mustFill ? "mustFill" : "";
	const titleClass = `${style["li-label"]} ${mustFillClass} li-title`;
	return (
		<div className={`${style["li-container"]} ${propClass} li-container`}>
			<div className={titleClass}>{title}</div>
			<div className={`${style["li-value"]} li-value`}>{children}</div>
		</div>
	);
}
