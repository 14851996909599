import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import api from "../../api";
import ConfirmDialog from "../../components/ConfirmDialog";
import StyledTableCell from "../../components/StyledTableCell";
import StyledTableRow from "../../components/StyledTableRow";
import { alerting } from "../../reducer/alerterSlice";
import { selectAppList } from "../../reducer/appListSlice";
import {
	openConfirmDialog,
	selectConfirmDialog,
} from "../../reducer/confirmDialogSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import style from "../account/Account.module.scss";

interface IuserInput {
	ip: string;
	comment: string;
}
const initUserInput = {
	ip: "",
	comment: "",
};

export default function Whitelist() {
	const { t } = useTranslation();
	const [userInput, setUserInput] = useState<IuserInput>(initUserInput);
	const [selectedIP, setSelectedIP] = useState<string>("");
	const dispatch = useAppDispatch();
	const confirmDialog = useAppSelector(selectConfirmDialog);
	const { isEnableWhitelist, list, addIP, deleteIP, updateIPStatus } =
		useWhitelist();
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInput((s) => ({ ...s, [key]: value }));
	};
	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onAddIP();
	};
	const onAddIP = () => {
		addIP(userInput.ip, userInput.comment);
		setUserInput(initUserInput);
	};

	const onDeleteIP = (ip: string) => {
		setSelectedIP(ip);
		const blank = { id: -1, name: "" };
		dispatch(openConfirmDialog(blank));
	};
	if (isEnableWhitelist === null) return <></>;
	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>
				{t("appAdmin.whitelist")} {!isEnableWhitelist && `(${t("disable")})`}
			</div>
			<div className={style["li-containers-row"]}>
				<div className={style["li-container"]} style={{ width: "92.7%" }}>
					<div className={style["li-value"]} style={{ width: "100%" }}>
						<form
							onSubmit={onSubmit}
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<TextField
								name="ip"
								placeholder={t("appAdmin.ip")}
								value={userInput.ip}
								onChange={onInputChange}
								variant="outlined"
								style={{ width: "49%" }}
								inputProps={{
									style: {
										padding: 5,
										margin: "3px 8px",
									},
								}}
							/>
							<TextField
								name="comment"
								placeholder={t("appAdmin.comment")}
								value={userInput.comment}
								onChange={onInputChange}
								variant="outlined"
								style={{ width: "49%" }}
								inputProps={{
									style: {
										padding: 5,
										margin: "3px 8px",
									},
									maxLength: 256,
								}}
							/>
						</form>
					</div>
				</div>

				<div className={style["li-container"]}>
					<div className={`${style["li-button"]} ${style["side-button"]}`}>
						<Button variant="contained" onClick={onAddIP}>
							{t("appAdmin.add")} {t("appAdmin.ip")}
						</Button>
					</div>
				</div>
			</div>
			<TableContainer component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>{t("appAdmin.ip")}</StyledTableCell>
							<StyledTableCell>{t("appAdmin.status")}</StyledTableCell>
							<StyledTableCell>{t("appAdmin.comment")}</StyledTableCell>
							<StyledTableCell>{t("appAdmin.setting")}</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{list.map((record) => (
							<StyledTableRow key={record.id}>
								<StyledTableCell component="th" scope="row">
									{record.ip}
								</StyledTableCell>
								<StyledTableCell component="th" scope="row">
									<div>{record.isEnabled ? t("enable") : t("disable")}</div>
									<div>
										<ToggleButton
											onTrigger={(bool) =>
												updateIPStatus(
													record.id,
													record.ip,
													bool,
													record.remarks
												)
											}
											isEnabled={record.isEnabled}
										/>
									</div>
								</StyledTableCell>
								<StyledTableCell
									component="th"
									scope="row"
									sx={{ wordWrap: "break-word", maxWidth: "120px" }}
								>
									{record.remarks}
								</StyledTableCell>
								<StyledTableCell component="th" scope="row">
									<Button
										variant="contained"
										sx={{ backgroundColor: "var(--main)" }}
										onClick={() => onDeleteIP(record.ip)}
									>
										delete
									</Button>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<ConfirmDialog
				title={`Confirm to delete IP?`}
				open={confirmDialog.open}
				onConfirm={() => {
					deleteIP(selectedIP);
				}}
			/>
		</div>
	);
}
interface Iwhitelist {
	id: number;
	ip: string;
	remarks: string;
	isEnabled: boolean;
}
function useWhitelist() {
	const { t } = useTranslation();
	const appId = useAppSelector(selectAppList).selected;
	const dispatch = useAppDispatch();
	const [isEnableWhitelist, setIsEnableWhitelist] = useState<
		true | false | null
	>(null);
	const [list, setList] = useState<Iwhitelist[]>([]);
	const [counter, setCounter] = useState(0);
	const refetch = () => setCounter((c) => c + 1);
	const addIP = async (ip: string, remarks: string) => {
		const res = await api.admin.addWhitelistIP({
			appId,
			ip,
			remarks,
			isEnabled: true,
		});
		if (!res) return;
		refetch();
		dispatch(alerting("success", t("appAdmin.ipAdded")));
		return res;
	};
	const updateIPStatus = async (
		id: number,
		ip: string,
		bool: boolean,
		remarks: string
	) => {
		const res = await api.admin
			.updateWhitelistIPStatus({ appId, ip, bool, remarks })
			.catch(refetch);
		const updateLocalStatus = (list: Iwhitelist[]) =>
			list.map((record) => {
				if (record.id !== id) return record;
				return { ...record, isEnabled: bool };
			});
		setList(updateLocalStatus);
		return res;
	};
	const deleteIP = async (ip: string) => {
		const res = await api.admin.deleteWhitelistIP({ appId, ip });
		if (!res) return;
		refetch();
		return res;
	};
	useEffect(() => {
		let mounted = true;
		async function fetch() {
			const result = await api.admin.getWhitelist(appId);
			if (!mounted) return;
			setList(result?.data || []);
		}
		fetch();
		return () => {
			mounted = false;
		};
	}, [appId, counter]);
	useEffect(() => {
		let mounted = true;
		async function fetch() {
			const result: any = await api.admin.getAppConfig(appId);
			if (!mounted) return;
			setIsEnableWhitelist(result?.data?.isEnableWhitelist || false);
		}
		fetch();
		return () => {
			mounted = false;
		};
	}, []);
	return { isEnableWhitelist, list, addIP, deleteIP, updateIPStatus };
}
function ToggleButton(props: {
	onTrigger: (bool: boolean) => void;
	isEnabled: boolean;
}) {
	const { onTrigger, isEnabled } = props;
	const [value, setValue] = useState(isEnabled);
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.checked;
		setValue(newValue);
		onTrigger(newValue);
	};
	return <Switch onChange={onChange} checked={value} />;
}
