import { IFeature } from "./../interface/appAdminFace";

/**
 *
 *
 * **/
function findItemParentFromRootByLeafId(
	root: IFeature,
	targetLeafId: number
): null | IFeature {
	if (!root || !root.children) {
		return null;
	}

	const targetLeaf = root.children.find(
		(leaf: IFeature) => leaf.id === targetLeafId
	);
	if (targetLeaf) {
		return root;
	}
	for (let i = 0; i < root.children.length; i++) {
		const result = findItemParentFromRootByLeafId(
			root.children[i],
			targetLeafId
		);
		if (result) {
			return result;
		}
	}
	return null;
}

function shiftTreeChildrenItemOrder(
	root: IFeature | null,
	targetLeafId: number,
	direction: "left" | "right",
	shiftNumber: number | undefined = 1
) {
	if (!root || root.children.length < 2) {
		return;
	}
	const targetItem = root.children.find(
		(item: any) => item.id === targetLeafId
	);
	if (!targetItem) {
		return;
	}
	const index = root.children.indexOf(targetItem);
	const shiftIndex =
		direction === "left" ? index - shiftNumber : index + shiftNumber;
	if (shiftIndex < 0 || shiftIndex > root.children.length - 1) {
		return;
	}
	const shiftItem = root.children[shiftIndex];
	root.children[shiftIndex] = targetItem;
	root.children[index] = shiftItem;
}

function swapTreeOrderSeq(
	root: IFeature | null,
	child1Index: number,
	child2Index: number
) {
	if (!root || root.children.length < 2) {
		return;
	}
	if (
		child1Index < 0 ||
		child2Index < 0 ||
		child1Index >= root.children.length ||
		child2Index >= root.children.length
	) {
		return;
	}
	const child1 = root.children[child1Index];
	const child2 = root.children[child2Index];

	if (!child1 || !child2) {
		return;
	}

	const child1Seq = root.children[child1Index].seq;
	const child2Seq = root.children[child2Index].seq;

	root.children[child1Index].seq = child2Seq;
	root.children[child2Index].seq = child1Seq;
}

function getUpdateOrderData(
	root: IFeature | null,
	child1Index: number | null | undefined,
	child2Index: number | null | undefined
) {
	if (child1Index == null || child2Index == null || !root) {
		return null;
	}

	const child1 = root.children[child1Index];
	const child2 = root.children[child2Index];

	return [
		{
			featureId: child1.id,
			ordering: child2.seq,
		},
		{
			featureId: child2.id,
			ordering: child1.seq,
		},
	];
}

export {
	findItemParentFromRootByLeafId,
	shiftTreeChildrenItemOrder,
	getUpdateOrderData,
	swapTreeOrderSeq,
};
