const LoginHost: string = process.env.REACT_APP_LOGIN_ENDPOINT || "";
const appUuid: string = process.env.REACT_APP_UUID || "";
if (LoginHost.length === 0) throw new Error("have no Login Host");
if (appUuid.length === 0) throw new Error("have no AppUUID");
export { LoginHost, appUuid };

export const isDev = process.env.NODE_ENV === "development";

export const versionNumber = "1.3.0";

export const drawerRoutesConfig = {
	admin: [
		{
			key: "appAdmin.appManagement",
			route: "/app-admin/app-management",
		},
		{
			key: "appAdmin.userManagement",
			route: "/app-admin/user-management",
		},
		{
			key: "appAdmin.roleManagement",
			route: "/app-admin/role-management",
		},
		{
			key: "appAdmin.featureManagement",
			route: "/app-admin/feature-management",
		},
		{
			key: "appAdmin.whitelist",
			route: "/app-admin/whitelist",
		},
	],
	superAdmin: [
		{
			key: "superAdmin.createApp",
			route: "/super-admin/create-app",
		},
		{
			key: "superAdmin.adminManagement",
			route: "/super-admin/management",
		},
	],
};
