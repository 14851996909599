import getAxiosInstance from "./axios";
const host = process.env.REACT_APP_AUTH_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "account");
interface loginFace {
	nonce: string;
	appId: number;
	email: string;
	password: string;
}
const apiList = {
	login: (data: loginFace) => api.post("login", data),
	verifyGA: () => api.post("verifyGA"),
	logout: () => api.post("logout"),
};
export default apiList;
