import { useState } from "react";
import LoginContainer from "../../components/LoginContainer";
import LoginBox from "../../components/LoginBox";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { LoginHost, appUuid } from "../../utils/config";

export default function Login() {
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("visitor." + key);
	const onSubmit = () => {
		window.location.href = LoginHost + `/login?appUuid=${appUuid}`;
	};
	return (
		<LoginContainer>
			<LoginBox>
				<Button onClick={onSubmit}>{t("login")}</Button>
			</LoginBox>
		</LoginContainer>
	);
}
