import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AlerterState {
	open?: boolean;
	text: string;
	severity: "error" | "warning" | "info" | "success";
	redirectTo?: string;
}

const initialState: AlerterState = {
	open: false,
	text: "",
	severity: "error",
	redirectTo: "",
};

export const alerterSlice = createSlice({
	name: "alerter",
	initialState,
	reducers: {
		open: (state, action: PayloadAction<AlerterState>) => {
			state.open = true;
			state.text = action.payload.text;
			state.severity = action.payload.severity;
		},
		close: (state) => {
			state.open = false;
			state.text = "";
		},
		setRedirectTo: (state, action: PayloadAction<string>) => {
			state.redirectTo = action.payload;
		},
	},
});

export const { open, close, setRedirectTo } = alerterSlice.actions;
export const alerting = (
	severity: AlerterState["severity"],
	text: string,
	config?: object
) =>
	open({
		text,
		severity,
		...config,
	});
export const selectAlerter = (state: RootState) => state.alerter;

export default alerterSlice.reducer;
