import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppSelector, useAppDispatch } from "../reducer/hooks";
import { closeConfirmDialog, selectConfirmDialog } from '../reducer/confirmDialogSlice';


const ConfirmDialog = (props: any) => {
    const { title, children, onConfirm } = props;
    const dispatch = useAppDispatch();
    const confirmDialog = useAppSelector(selectConfirmDialog);


    function onConfirmDialog() {
        dispatch(closeConfirmDialog())
        onConfirm();
    }

    return (
        <Dialog
            open={confirmDialog.open!}
            onClose={() => dispatch(closeConfirmDialog())}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => dispatch(closeConfirmDialog())}
                    color="primary"
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onConfirmDialog();
                    }}
                    color="warning"
                // color="default"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;