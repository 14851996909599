import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import style from "../account/Account.module.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../api";
import { alerting } from "../../reducer/alerterSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import TreeView from "@mui/lab/TreeView";
import MinusSquare from "../../components/MinusSquare";
import PlusSquare from "../../components/PlusSquare";
import CloseSquare from "../../components/CloseSquare";
import StyledTreeItem from "../../components/StyledTreeItem";
import Checkbox from "@mui/material/Checkbox";
import { fetchFeatureList, selectAppAdmin } from "../../reducer/appAdminSlice";
import { generateNonce, newGetAllParentIds } from "../../utils/helper";
import { getChildById } from "../../utils/treeview";
import { IFeature, IParentFeatureList } from "../../interface/appAdminFace";
import { selectCurrentApp } from "../../reducer/appListSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuIcon from "@mui/icons-material/MenuBook";
import FunctionIcon from "@mui/icons-material/Build";
import { Box } from "@mui/material";

const initUserInputs = {
	roleName: "",
	description: "",
};

export default function CreateRole() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const popsError = (text: string) => dispatch(alerting("error", text));
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const state = useAppSelector(selectAppAdmin);
	const currentApp = useAppSelector(selectCurrentApp);
	const [selectedIds, rawSetSelectedIds] = useState<number[]>([]);
	const setSelectedIds = (tickedIds: Array<number>) => {
		const selectedIdArr = newGetAllParentIds(
			parentFeatureList,
			[...selectedIds, ...tickedIds],
			new Set(),
			new Set()
		);
		rawSetSelectedIds(selectedIdArr ? selectedIdArr : []);
	};

	const parentFeatureList: IParentFeatureList = {
		id: -1,
		name: "All Feature",
		children: state.featureList,
		featureType: 1,
	};

	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};

	const onRoleCreate = async () => {
		if (
			!selectedIds.length ||
			(selectedIds.length === 1 && selectedIds[0] === -1)
		) {
			return popsError("Feature Cannot be Empty");
		}
		if (!userInputs.roleName || !userInputs.description) {
			return popsError("Input Field Cannot be Empty");
		}
		const nonce = generateNonce();
		const data: any = {
			nonce,
			appId: currentApp!.appId,
			roleName: userInputs.roleName,
			description: userInputs.description,
			featureList: selectedIds.filter((id) => id !== -1),
		};
		let result: any = null;
		result = await api.admin.createRole(data);
		if (!result) return Error;
		navigate(-1);
		const message = `Role ${userInputs.roleName} Create Success`;
		dispatch(alerting("success", message));
	};

	function renderTree(nodes: IFeature | IParentFeatureList) {
		const allNode: number[] = getChildById(parentFeatureList, nodes.id);
		const onClickHandler = (nodes: IFeature | IParentFeatureList) => {
			if (
				Array.isArray(nodes.children) &&
				selectedIds.includes(nodes.id) &&
				!allNode.every((id) => selectedIds.includes(id))
			) {
				setSelectedIds(allNode);
				return;
			}

			if (
				Array.isArray(nodes.children) &&
				allNode.every((id) => selectedIds.includes(id))
			) {
				rawSetSelectedIds((prev) => prev.filter((id) => !allNode.includes(id)));
				return;
			}

			if (selectedIds.includes(nodes.id)) {
				rawSetSelectedIds((prev) => prev.filter((id) => id !== nodes.id));
			} else {
				if (nodes.id === -1) {
					rawSetSelectedIds([-1]);
					return;
				}
				setSelectedIds([nodes.id]);
			}
		};

		const treeItemLabel = (
			<Box key={nodes.id} sx={{ display: "flex", alignItems: "center" }}>
				<Checkbox
					checked={selectedIds.some((item) => item === nodes.id)}
					indeterminate={selectedIds.some(
						(item) =>
							item === nodes.id &&
							nodes.children &&
							!allNode.every((id) => selectedIds.includes(id))
					)}
					onClick={(e) => {
						e.stopPropagation();
						onClickHandler(nodes);
					}}
				/>

				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ marginRight: "4px" }}>
						{nodes?.featureType === 1 ? (
							<MenuIcon sx={{ marginTop: "3px" }} />
						) : (
							<FunctionIcon sx={{ marginTop: "6px" }} />
						)}
					</div>
					<div>{nodes.name}</div>
				</div>
			</Box>
		);
		return (
			<StyledTreeItem
				key={nodes.id}
				nodeId={nodes.id.toString()}
				label={treeItemLabel}
			>
				{Array.isArray(nodes.children)
					? nodes.children.map((node: any) => renderTree(node))
					: null}
			</StyledTreeItem>
		);
	}

	useEffect(() => {
		if (currentApp && currentApp.appId) {
			dispatch(fetchFeatureList(currentApp!.appId));
		}
	}, []);

	return (
		<div
			className={style["content-wrap"]}
			style={{ height: "calc(100vh - 100px)", minHeight: "650px" }}
		>
			<div className={style["title"]}>{t("appAdmin.createRole")}</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.roleName")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						id="outlined"
						name="roleName"
						variant="outlined"
						onChange={setInputData}
						value={userInputs.roleName}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.description")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						id="outlined"
						name="description"
						variant="outlined"
						onChange={setInputData}
						value={userInputs.description}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("appAdmin.assignedRoles")}
				</div>
			</div>
			<TreeView
				className={style["featureTree"]}
				aria-label="customized"
				defaultExpanded={["-1"]}
				defaultCollapseIcon={<MinusSquare />}
				defaultExpandIcon={<PlusSquare />}
				defaultEndIcon={<CloseSquare />}
				sx={{
					height: "calc(100vh - 450px)",
					minHeight: "300px",
					flexGrow: 1,
					maxWidth: 400,
					overflowY: "auto",
				}}
				multiSelect={true}
			>
				{parentFeatureList && renderTree(parentFeatureList)}
			</TreeView>
			<div className={`${style["li-button"]} ${style["li-button-long"]}`}>
				<div>
					<Button color="primary" variant="contained" onClick={onRoleCreate}>
						{t("account.submit")}
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => navigate("/app-admin/role-management")}
					>
						{t("account.back")}
					</Button>
				</div>
			</div>
		</div>
	);
}
