import { useState, useEffect, useMemo } from "react";
import style from "../../styles/contentWrap.module.scss";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../components/StyledTableCell";
import StyledTableRow from "../../components/StyledTableRow";
import { alerting } from "../../reducer/alerterSlice";
import {
	selectLister,
	fetchAppList,
	setSelected,
} from "../../reducer/superAppListSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import api from "../../api";
import { generateNonce } from "../../utils/helper";
import { adminUserFace } from "../../interface/adminUserFace";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export default function AdminManagement() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const [adminUsers, setAdminUsers] = useState<adminUserFace[]>([]);
	const [needFetch, setNeedFetch] = useState(false);
	const [userInput, setUserInput] = useState("");
	const superAppLister = useAppSelector(selectLister);

	const selectedApp = superAppLister.selected;
	const appUuidMemo = useMemo(
		() => superAppLister.list.find((app) => app.appId === selectedApp)?.appUuid,
		[superAppLister]
	);
	const copyAppUuid = async () => {
		try {
			if ("clipboard" in navigator) {
				await navigator.clipboard.writeText(appUuidMemo?.toString() || "");
			} else {
				document.execCommand("copy", true, appUuidMemo?.toString() || "");
			}
			dispatch(alerting("success", "App UUID copied!"));
		} catch (e) {
			alerting("error", "Cannot copy App UUID!");
		}
	};
	const isLoading = selectedApp <= 0;
	const nonce = generateNonce();
	useEffect(() => {
		dispatch(fetchAppList()); //used thunk
	}, [dispatch]);
	async function fetchAdminUser() {
		const result = await api.superAdmin.adminList(selectedApp);
		if (!result) return;
		setAdminUsers(result.data);
		setNeedFetch(false);
	}
	useEffect(() => {
		if (isLoading) return;
		fetchAdminUser();
	}, [selectedApp]);
	useEffect(() => {
		if (!needFetch) return;
		fetchAdminUser();
	}, [needFetch]);

	const handleNetworkChange = (event: SelectChangeEvent) => {
		dispatch(setSelected(Number(event.target.value)));
	};
	const addAdmin = async () => {
		const params = { email: userInput, appId: selectedApp, nonce };
		const result = await api.superAdmin.createAdmin(params);
		if (!result) return;
		setUserInput("");
		dispatch(alerting("success", `added ${userInput} as app admin`));
		setNeedFetch(true);
	};
	if (isLoading) return <>loading</>;
	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("superAdmin.adminManagement")}</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]}`}>{t("superAdmin.appName")}</div>
				<div className={style["li-value"]}>
					<Select
						value={superAppLister.selected.toString()}
						onChange={handleNetworkChange}
						displayEmpty
						sx={{
							color: "var(--black)",
							bgcolor: "var(--white)",
						}}
					>
						{superAppLister.list.map((app) => (
							<MenuItem key={app.appId} value={app.appId}>
								{app.appName}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]}`}>{t("superAdmin.appUuid")}</div>
				<div className={style["li-value"]}>
					<div
						className={`${style["flexContainer"]} ${style["copySecretContainer"]}`}
						style={{ width: "max-content" }}
						onClick={copyAppUuid}
					>
						<span className={style["forCopyText"]}>
							{
								superAppLister.list.find((app) => app.appId === selectedApp)!
									.appUuid
							}
						</span>
						<FileCopyIcon fontSize="inherit" />
					</div>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]}`}>{t("superAdmin.addAdmin")}</div>
				<div className={style["li-value"]}>
					<TextField
						id="filled"
						variant="outlined"
						value={userInput}
						onChange={(e) => setUserInput(e.target.value)}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
				<div
					className={`${style["li-button"]} ${style["side-button"]}`}
					onClick={addAdmin}
				>
					<Button variant="contained">{t("superAdmin.add")}</Button>
				</div>
			</div>
			<TableContainer component={Paper}>
				<Table sx={{ maxWidth: 1000 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>{t("superAdmin.userName")}</StyledTableCell>
							<StyledTableCell align="center">
								{t("superAdmin.role")}
							</StyledTableCell>
							<StyledTableCell align="center">
								{t("superAdmin.status")}
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{adminUsers.map((user) => (
							<StyledTableRow key={user.loginId}>
								<StyledTableCell component="th" scope="row">
									{user.email}
								</StyledTableCell>
								<StyledTableCell align="right">App Admin</StyledTableCell>
								<StyledTableCell align="right">{user.status}</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
