import getAxiosInstance from "./axios";
const host = process.env.REACT_APP_ADMIN_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "superAdmin");
interface createAppFace {
	nonce: string;
	appName: string;
	isEnableTwoFA: boolean;
	iconUrl: string;
	loginTokenDuration: number;
	loginCallbackUrl: string;
}
interface createAdminFace {
	nonce: string;
	appId: number;
	email: string;
}
const apiList = {
	createApp: (data: createAppFace) => api.post("app/create", data),
	createAdmin: (data: createAdminFace) => api.post("admin/create", data),
	superAppList: () => api.get("app/list"),
	adminList: (appId: number) => api.get(`admin/list/${appId}`),
};
export default apiList;
