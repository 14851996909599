import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Login from "./view/login";
import PersistentDrawerLeft from "./view/account/PersistentDrawerLeft";
import CreateApp from "./view/superAdmin/CreateApp";
import AdminManagement from "./view/superAdmin/AdminManagement";
import AppManagement from "./view/appAdmin/AppManagement";
import UserManagement from "./view/appAdmin/UserManagement";
import UserInfo from "./view/appAdmin/UserInfo";
import EditRole from "./view/appAdmin/EditRole";
import RoleManagement from "./view/appAdmin/RoleManagement";
import Whitelist from "./view/appAdmin/Whitelist";
import CreateRole from "./view/appAdmin/CreateRole";
import FeatureManagement from "./view/appAdmin/FeatureManagement";
import CssBaseline from "@mui/material/CssBaseline";
import Alerter from "./components/Alerter";
import Redirect from "./components/Redirect";
import Auth from "./components/Auth";
import "./styles/general.module.scss";
import { selectProfile } from "./reducer/profileSlice";
import { selectAppList } from "./reducer/appListSlice";
import { useAppSelector } from "./reducer/hooks";

function App() {
	const profile = useAppSelector(selectProfile);
	const appId = useAppSelector(selectAppList).selected;
	const { isSuperAdmin, isLogged } = profile;
	const ProtectedRoutes = ({ needSuperAdmin }: any) => {
		if (!isLogged) return <Navigate to="/" />;
		if (needSuperAdmin && !isSuperAdmin) return <Navigate to="/" />;
		if (appId <= 0) return <>loading...</>;
		return <Outlet />;
	};

	return (
		<div className="App">
			<CssBaseline />
			<Alerter />
			<Auth />
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="login" element={<Login />} />
				<Route path="redirect" element={<Redirect />} />
				<Route element={<ProtectedRoutes needSuperAdmin />}>
					<Route
						path="super-admin/create-app"
						element={<PersistentDrawerLeft children={<CreateApp />} />}
					/>
					<Route
						path="super-admin/management"
						element={<PersistentDrawerLeft children={<AdminManagement />} />}
					/>
				</Route>
				<Route element={<ProtectedRoutes />}>
					<Route
						path="app-admin/user-management"
						element={<PersistentDrawerLeft children={<UserManagement />} />}
					/>
					<Route
						path="app-admin/user-management/add"
						element={<PersistentDrawerLeft children={<UserInfo />} />}
					/>
					<Route
						path="app-admin/user-management/edit"
						element={<PersistentDrawerLeft children={<UserInfo />} />}
					/>
					<Route
						path="app-admin/app-management"
						element={<PersistentDrawerLeft children={<AppManagement />} />}
					/>
					<Route
						path="app-admin/role-management/edit-role/:roleId"
						element={<PersistentDrawerLeft children={<EditRole />} />}
					/>
					<Route
						path="app-admin/role-management"
						element={<PersistentDrawerLeft children={<RoleManagement />} />}
					/>
					<Route
						path="app-admin/role-management/create-role"
						element={<PersistentDrawerLeft children={<CreateRole />} />}
					/>
					<Route
						path="app-admin/feature-management"
						element={<PersistentDrawerLeft children={<FeatureManagement />} />}
					/>
					<Route
						path="app-admin/whitelist"
						element={<PersistentDrawerLeft children={<Whitelist />} />}
					/>
				</Route>
				<Route
					path="*"
					element={
						<main style={{ padding: "1rem" }}>
							<p>404 not found!</p>
						</main>
					}
				/>
			</Routes>
		</div>
	);
}

export default App;
