interface returnCodeDictFace {
	[code: string]: string;
}
export const returnCodeDict: returnCodeDictFace = {
	"-5": "Internal server error",
	"-7": "Login token expired",
	"-8": "Invalid login token",
	"-9": "Database error",
	"-13": "Network error",
	"-22": "Invalid argument",
	"-31": "Invalid login token type",
	"-32": "User already exist",
};
