import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface ProfileState {
	token: string;
	isSuperAdmin: boolean;
	isLogged: boolean;
	lastAppId: number;
}

const initialState: ProfileState = {
	token: "",
	isSuperAdmin: false,
	isLogged: false,
	lastAppId: -1,
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		setLastAppId: (state, action: PayloadAction<string | number>) => {
			state.lastAppId = Number(action.payload);
		},
		login: (state, action: PayloadAction<boolean>) => {
			state.isSuperAdmin = action.payload;
			state.isLogged = true;
		},
		logout: () => initialState,
	},
});

export const { setToken, login, setLastAppId, logout } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
