import {
	configureStore,
	combineReducers,
	ThunkAction,
	Action,
} from "@reduxjs/toolkit";
import alerterReducer from "./alerterSlice";
import profileReducer from "./profileSlice";
import appAdminReducer from "./appAdminSlice";
import userSettingReducer from "./userSettingSlice";
import appListReducer from "./appListSlice";
import appUserReducer from "./appUserSlice";
import superAppListReducer from "./superAppListSlice";
import confirmDialogReducer from "./confirmDialogSlice";

import { createLogger } from "redux-logger";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { isDev } from "../utils/config";
const persistConfig = {
	key: "root",
	version: 1,
	storage, //save in localStorage
	whitelist: ["profile"], // only profile will be persisted
};
const reducers = combineReducers({
	alerter: alerterReducer,
	profile: profileReducer,
	appAdmin: appAdminReducer,
	userSetting: userSettingReducer,
	appLister: appListReducer,
	appUsers: appUserReducer,
	superAppLister: superAppListReducer,
	confirmDialog: confirmDialogReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

//localStorage.setItem('showLog',true)
const showLog = localStorage.getItem("showLog") === "true";
const logger = createLogger({
	predicate: () => isDev && showLog,
});

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(logger),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
