import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getItemsByIds } from "../utils/helper";
import { useAppSelector } from "../reducer/hooks";
import { selectCurrentApp } from "../reducer/appListSlice";

interface propFace {
	title?: string;
	list: { id: number; name: string; appId: number; status: number }[];
	selectedItems: string[];
	setSelected: React.Dispatch<React.SetStateAction<never[]>>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function MultipleSelectChip(props: propFace) {
	const { list, selectedItems, setSelected, title = "" } = props;
	const currentApp = useAppSelector(selectCurrentApp);

	const handleChange = (event: any) => {
		const { value } = event.target;
		setSelected(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	const sortByAlphabetAndAppNature = (
		data: {
			id: number;
			name: string;
			appId: number;
			status: number;
		}[],
		appNature?: "master" | "derived"
	) => {
		const sortedData = data.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});

		const derivedAppArr = sortedData.filter(
			(item) => item.appId === currentApp?.appId
		);
		const masterAppArr = sortedData.filter(
			(item) => item.appId !== currentApp?.appId
		);

		const outcome = [...derivedAppArr, ...masterAppArr];
		if (appNature === "master") return masterAppArr;
		else if (appNature === "derived") return derivedAppArr;
		return outcome;
	};

	return (
		<FormControl sx={{ width: 300 }}>
			<InputLabel>{title}</InputLabel>
			<Select
				labelId="multiple-chip-label"
				multiple
				displayEmpty
				value={selectedItems}
				onChange={handleChange}
				input={<OutlinedInput id="select-multiple-chip" />}
				renderValue={(selected) => {
					if (!selected.length) {
						return <em style={{ color: "#aaa" }}>One or more selection(s)</em>;
					}

					return (
						<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
							{sortByAlphabetAndAppNature(
								getItemsByIds(selected, list),
								"derived"
							).map((item) => (
								<Chip
									key={item.id}
									label={
										<>
											{item.name}{" "}
											{item.status === -1 && (
												<span style={{ color: "#aaa", marginLeft: 4 }}>
													(Disabled)
												</span>
											)}
										</>
									}
								/>
							))}
							{sortByAlphabetAndAppNature(
								getItemsByIds(selected, list),
								"master"
							).map((item) => (
								<Chip
									key={item.id}
									label={
										<>
											{item.name}{" "}
											<span style={{ color: "#29abc1", marginLeft: 4 }}>
												(Master)
											</span>
											{item.status === -1 && (
												<span style={{ color: "#aaa", marginLeft: 4 }}>
													(Disabled)
												</span>
											)}
										</>
									}
								/>
							))}
						</Box>
					);
				}}
				MenuProps={MenuProps}
			>
				<MenuItem disabled value="">
					<em>One or more selection(s)</em>
				</MenuItem>
				{sortByAlphabetAndAppNature(list.slice(), "derived").map((item) => (
					<MenuItem key={item.id} value={item.id}>
						{item.name}{" "}
						{item.status === -1 && (
							<span style={{ color: "#aaa", marginLeft: 4 }}>(Disabled)</span>
						)}
					</MenuItem>
				))}
				{sortByAlphabetAndAppNature(list.slice(), "master").map((item) => (
					<MenuItem key={item.id} value={item.id}>
						<>
							{item.name}
							<span style={{ color: "#29abc1", marginLeft: 4 }}>(Master)</span>
							{item.status === -1 && (
								<span style={{ color: "#aaa", marginLeft: 4 }}>(Disabled)</span>
							)}
						</>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
